<template>
  <div class="projects">
    Ihaleler...
  </div>
</template>
<script src="./projects.ts"/>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>


</style>