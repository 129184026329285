import { Options, Vue } from 'vue-class-component';
import UserView from '@/components/userView.vue'; 

@Options({
    name: 'Start',
    components: {
      UserView
    },
    props: {

    },
    emits: {

    },
  })
export default class Start extends Vue {}