
export class CommonFunctions {

    public static getErrorMsg(error: any): void {
        let errorMessage = "An known error occurred!";
        if (error instanceof Error) {
            errorMessage = error.message;
        }
        console.log(errorMessage);
    }

    public static numToStr(value: number, locale: string): string {
        if (isNaN(value)) {
            return 'n/a';
        }

        const result = value.toLocaleString(locale, {maximumFractionDigits: 3});
        return result;
    }
}
