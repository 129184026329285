import { User } from "./user";

export class UserListResponse {
    public userList: User[] = [];

    constructor(src: any) {
        if (!Array.isArray(src)) {
            return;
        }
        this.userList = src;
    }
}
