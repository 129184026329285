<template>
  <section class="pageContents">
    <div class="pageContents-text container">
      <Projects />
    </div>
  </section>
</template>

<script>
import { Options, Vue } from 'vue-class-component';
import Projects from '@/components/projects.vue';

@Options({
    name: 'ProjectsView',
    components: {
        Projects,
    },
    props: {
      msg: String
    },
    emits: {
        //select: (myProperty: string) => typeof myProperty === 'string'
    },
  })
export default class ProjectsView extends Vue {}
</script>
