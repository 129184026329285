<template>
  <div class="assets">
    Malzemeler...
  </div>
</template>
<script src="./assets.ts"/>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../style/table';

</style>