import { User } from '@/common/models/user';
import { Options, Vue } from 'vue-class-component';
import Modal  from '@/common/modal.vue';

@Options({
  name: 'EditUser',
  components: {
    Modal
  },
  props: {
    user: Object
  },
  emits: {
    userUpdated: (userUpdated: User) => typeof userUpdated === 'object'
  },
})
export default class EditUser extends Vue {
    user!:User;

    public showModal =  false;
    
    public onClose() { 
      this.$emit('userUpdated', this.user);   
      this.showModal = false;
    }
}
