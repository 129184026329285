<template v-if="user !== null">
    <div >
        <button id="show-modal" @click="showModal = true">Show Modal</button>
        <modal v-if="showModal" @close="onClose" >
            <template v-slot:header>
                <h3>Edit User</h3>
            </template>
            <template v-slot:body>
                <table>
                    <tr>
                        <th>FirstName</th>
                        <th>LastName</th>
                        <th>UserStatus</th>
                        <th>StartDateTime</th>
                        <th>endDateTime</th>
                    </tr>
                    <tr>
                        <th><input type="text" v-model="user.firstName"/></th>
                        <th><input type="text" v-model="user.lastName"/></th>
                        <th><input type="text" v-model="user.userStatus"/></th>
                        <th><input type="text" v-model="user.startDateTime"/></th>
                        <th><input type="text" v-model="user.endDateTime"/></th>
                    </tr>
                </table>

            </template>
        </modal> 
    </div>
</template>
<script src="./editUser.ts"/>

<style lang="scss" scoped>


</style>
