<template>
  <div class="tests">
    Testler...
  </div>
</template>
<script src="./tests.ts"/>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../style/table';

</style>