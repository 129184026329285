import { Options, Vue } from 'vue-class-component';

@Options({
    name: 'Modal',
    components: {
  
    },
    props: {

    },
    emits: {
      'close':false // or ['close']
    }
  })
  export default class Modal extends Vue {
    public close() {
        this.$emit('close');
    }
  }
