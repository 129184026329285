<template>
  <div class="userView" v-if="getUserStore.getters.isLoggedIn">
    <input type="text" v-model="userFirstName"/>
    <button v-on:click="onAdd()">Add</button>
    <br><br>
    <input type="text" v-model="selectedId"/>
    <button v-on:click="onDelete()">Delete</button>
    <br><br>
    <div>
    <table>
        <tr>
            <th>Id</th>
            <th>FirstName</th>
            <th>LastName</th>
            <th>UserStatus</th>
            <th>StartDate</th>
            <th>EndDate</th>
            <th>Edit User</th>
        </tr>
        <tr v-for="user in users" :key="user.id" class="selectable"  >
            <td>{{ user.id }}</td>
            <td>{{ user.firstName }}</td>
            <td>{{ user.lastName }}</td>
            <td>{{ user.userStatus }}</td>
            <td>{{ user.startDateTime }}</td>
            <td>{{ user.endDateTime }}</td>
            <td><edit-user :user="user" @userUpdated="onUpdatedUser"/></td>
        </tr>     
    </table>
    </div>

    
  </div>
</template>
<script src="./userView.ts"/>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../style/table';

</style>